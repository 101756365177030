import { Heroo, About, Skills } from "../components/portifolio";

type Props = {};

const Portifolio = (props: Props) => {
  return (
    <div>
      <Heroo />
      <About />
      <Skills />
    </div>
  );
};

export default Portifolio;
