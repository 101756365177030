export type test = {
  size: string;
  left: string;
  duration: string;
  delay: string;
  top: string;
};

export const mover: Array<test> = [
  {
    size: "9px",
    left: "1090px",
    duration: "2.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "14px",
    left: "1090px",
    duration: "2.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "7px",
    left: "1010px",
    duration: "1.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "9px",
    left: "1110px",
    duration: "1.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "10px",
    left: "290px",
    duration: "3s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "10px",
    left: "790px",
    duration: "3s",
    delay: "1.5s",
    top: "0px",
  },
  {
    size: "10px",
    left: "890px",
    duration: "3s",
    delay: "0.5s",
    top: "0px",
  },
  {
    size: "12px",
    left: "1090px",
    duration: "3s",
    delay: "0.5s",
    top: "0px",
  },
  {
    size: "10px",
    left: "990px",
    duration: "2s",
    delay: "0.5s",
    top: "0px",
  },
  {
    size: "8px",
    left: "40px",
    duration: "2.5s",
    delay: "0.,5s",
    top: "0px",
  },
  {
    size: "13px",
    left: "460px",
    duration: "2.5s",
    delay: "0.,5s",
    top: "0px",
  },
  {
    size: "9px",
    left: "590px",
    duration: "3s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "14px",
    left: "490px",
    duration: "3s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "14px",
    left: "550px",
    duration: "2.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "6px",
    left: "450px",
    duration: "3s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "6px",
    left: "90px",
    duration: "2.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "6px",
    left: "50px",
    duration: "2.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "8px",
    left: "30px",
    duration: "2s",
    delay: "1.3s",
    top: "0px",
  },
  {
    size: "8px",
    left: "530px",
    duration: "2.9s",
    delay: "2.3s",
    top: "0px",
  },
  {
    size: "8px",
    left: "630px",
    duration: "2.7s",
    delay: "3.3s",
    top: "0px",
  },
  {
    size: "8px",
    left: "660px",
    duration: "1.7s",
    delay: "0.3s",
    top: "0px",
  },
  {
    size: "8px",
    left: "680px",
    duration: "2.7s",
    delay: "0.9s",
    top: "0px",
  },
  {
    size: "9px",
    left: "700px",
    duration: "2.7s",
    delay: "0.3s",
    top: "0px",
  },
  {
    size: "14px",
    left: "550px",
    duration: "2.7s",
    delay: "0.3s",
    top: "0px",
  },
  {
    size: "11px",
    left: "580px",
    duration: "2.7s",
    delay: "0.3s",
    top: "0px",
  },
  {
    size: "11px",
    left: "250px",
    duration: "2s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "13px",
    left: "440px",
    duration: "2s",
    delay: "4s",
    top: "0px",
  },
  {
    size: "7px",
    left: "210px",
    duration: "2.5s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "12px",
    left: "590px",
    duration: "3s",
    delay: "1s",
    top: "0px",
  },
  {
    size: "6px",
    left: "190px",
    duration: "5s",
    delay: "2s",
    top: "0px",
  },
  {
    size: "8px",
    left: "390px",
    duration: "4s",
    delay: "0.5s",
    top: "0px",
  },
  {
    size: "5px",
    left: "490px",
    duration: "2s",
    delay: "2.5s",
    top: "130px",
  },
  {
    size: "8px",
    left: "0px",
    duration: "2s",
    delay: "1.5s",
    top: "130px",
  },
  {
    size: "15px",
    left: "0px",
    duration: "1.5s",
    delay: "2.5s",
    top: "230px",
  },
  {
    size: "15px",
    left: "0px",
    duration: "2.5s",
    delay: "0.5s",
    top: "330px",
  },
  {
    size: "10px",
    left: "0px",
    duration: "1.5s",
    delay: "0.5s",
    top: "370px",
  },
];
